<template>
  <div id="App">
    <van-list
      style="margin-top:-15px;"
      v-model="loading"
      :finished="finished"
      finished-text="近一个月预约记录"
      @load="onLoad"
    >
      <van-divider />
      <van-swipe-cell v-for="(item, index) in list" :key="item.ID">
        <van-card
          :key="item.ID"
          :tag="item.YYZT == '已取消' ? '已取消' : ''"
          :price="item.FY"
          :desc="item.CDMC + ' ' + item.YYKSSJ + ' - ' + item.YYJZSJ"
          :title="item.KSRQ + ' ' + item.XQ"
          :thumb="(item.CDMC.indexOf('乒乓') != -1 ? ppqImgPath : '')
          +(item.CDMC.indexOf('羽毛球') != -1 ? ymqImgPath : '') 
          +(item.CDMC.indexOf('篮球') != -1 ? lqImgPath : '') 
          +(item.CDMC.indexOf('气排球') != -1 ? qpqImgPath : '') 
          +(item.CDMC.indexOf('足球') != -1 ? zqImgPath : '') 
          +(item.CDMC.indexOf('健身房') != -1 ? jsfImgPath : '')"
          class="goods-card"
          @click="onClickCell(item.CODE)"
        >
          <template #tags>
            <van-tag
              plain
              :type="
                (item.YYZT == '已取消' ? 'danger' : '') +
                  (item.YYZT == '预约中' ? 'warning' : '') +
                  (item.YYZT == '使用中' ? 'primary' : '') +
                  (item.YYZT == '已完成' ? 'success' : '')
              "
              v-show="item.YYZT != ''"
              >{{ item.YYZT }}</van-tag
            >
          </template>
        </van-card>
        <template #right>
          <van-button
            square
            text="取消订单"
            type="danger"
            class="delete-button"
            v-show="item.SFKQX == '1'"
            @click="onClick(item, index)"
          />
        </template>
      </van-swipe-cell>
    </van-list>
    <Tabbar></Tabbar>
  </div>
</template>
<style>
/* .van-card__title{
  font-size: large;
}
.van-card__desc{
  font-size:medium;
} */
.goods-card {
  margin: 0;
  background-color: white;
}
.delete-button {
  height: 100%;
}
</style>

<script>
import { mapState } from "vuex";
import Tabbar from "../components/Tabbar.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      title: "查看预约",
      list: [],
      loading: false,
      finished: false
    };
  },
  components: {
    Tabbar
  },
  computed: {
    ...mapState(["userInfo", "ppqImgPath", "ymqImgPath", "zqImgPath", "qpqImgPath", "lqImgPath", "jsfImgPath"])
  },
  methods: {
    onClickCell(key) {
      this.$router.push({
        path: "/showyyinfo",
        name: "ShowYyInfo",
        query: {
          code: key
        }
      });
    },
    onClick(key, index) {
      Dialog.confirm({
        title: "取消预约",
        message: "是否取消当前选中的预约？"
      }).then(() => {
        var that = this;
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Method: "API_setCancelYY",
              Params: [key.ID]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "200") {
              if (response.data.Table[0].RET) {
                that.$set(that.list, index, {
                  ID: key.ID,
                  FY: key.FY,
                  CDMC: key.CDMC,
                  YYKSSJ: key.YYKSSJ,
                  YYJZSJ: key.YYJZSJ,
                  KSRQ: key.KSRQ,
                  XQ: key.XQ,
                  YYZT: "已取消",
                  SFKQX: "0"
                });
              }
            } else {
              Dialog({ message: response.data.msg });
            }
          })
          .catch(error => {
            Dialog({ message: error.message });
          });
      });
    },
    onLoad() {
      this.loading = true;
      var that = this;
      that
        .$axios({
          method: "post",
          url: "/api/Main",
          data: {
            Method: "API_GetYYLOG",
            Params: [that.userInfo.userCardID]
          },
          headers: {
            Authorization: "Bearer " + that.userInfo.token
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            that.list = response.data.Table;
            that.finished = true;
          } else {
            Dialog({ message: response.data.msg });
            that.finished = true;
          }
        })
        .catch(error => {
          Dialog({ message: error.message });
          that.finished = true;
        });
    }
  }
};
</script>